<template>
  <div class="text-left">
    <div
      class="row m-0"
      :class="{'bg_light': background === 'light', 'p-5': isDesktop && background === 'light'}"
      v-if="!details || loading"
    >
      <div class="col-12 mb-1 mb-md-2 p-0">
        Loading profile...
      </div>
    </div>
    <div
      class="row m-0"
      :class="{'bg_light': background === 'light', 'p-5': (isDesktop && background === 'light'), 'pt-5': (!isDesktop)}"
      style="overflow-y: auto;"
      v-else
    >
      <div class="col-12 pb-3">
        <div
          class="col-12 mb-1 mb-md-2 p-0 pb-2"
          v-if="background === 'light'"
        >
          Please kindly complete the information below so that we may activate your online profile (you’ll only ever need to do this once!).
        </div>
        <div
          class="col-12 mb-1 mb-md-2 p-0 pt-4 pb-3 bold"
          v-if="background === 'light'"
        >
          Person Responsible for Booking:
        </div>
        <div class="col-12 mb-1 mb-md-2 p-0 pb-2">
          Title
        </div>
        <v-select
          :class="{'select-transparent': background !== 'light'}"
          label="name"
          :options="titles"
          v-model="details.title"
          :searchable="false"
          :clearable="false"
          :disabled="saving"
          :reduce="item => item.value"
        />
      </div>
      <div class="col-lg-6 col-12 pb-4">
        <div class="col-12 mb-1 mb-md-2 p-0">
          First Name (as per passport or ID document)
        </div>
        <input
          class="form-control"
          :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand']"
          type="text"
          v-model="details.name"
        >
      </div>
      <div class="col-lg-6 col-12 pb-4">
        <div class="col-12 mb-1 mb-md-2 p-0">
          Prefered Name (if different from document)
        </div>
        <input
          class="form-control"
          :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand']"
          type="text"
          v-model="details.namePreferred"
        >
      </div>
      <div class="col-lg-6 col-12 pb-4">
        <div class="col-12 m-0 p-0">
          Surname
        </div>
        <input
          class="form-control"
          :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand']"
          type="text"
          v-model="details.surname"
        >
      </div>
      <div class="col-lg-6 col-12 pb-3">
        <div class="col-12 mb-1 mb-md-2 p-0 pb-2">
          Nationality
        </div>
        <v-select
          :class="{'select-transparent': background !== 'light'}"
          label="name"
          :options="countries"
          v-model="details.countryCd"
          :clearable="false"
          :disabled="saving"
          :reduce="item => item.cd"
        />
      </div>

      <div class="col-lg-6 col-12 pb-4">
        <div class="col-12 mb-1 mb-md-2 p-0">
          Date of Birth
        </div>
        <datepicker
          :input-class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand', 'form-control']"
          :calendar-class="[background !== 'light' ? 'bg_darkest' : 'bg_light']"
          :value="details.dob"
          @selected="changeDate($event, 'dob')"
        ></datepicker>
      </div>

      <div
        class="col-lg-6 col-12 pb-4"
        v-if="details.countryCd === 'ZA'"
      >
        <div class="col-12 mb-1 mb-md-2 p-0">
          ID Number
        </div>
        <input
          class="form-control"
          :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand']"
          type="text"
          v-model="details.idNo"
        >
      </div>

      <div
        class="col-lg-6 col-12 pb-4"
        v-if="details.countryCd !== 'ZA'"
      >
        <div class="col-12 mb-1 mb-md-2 p-0">
          Passport Number
        </div>
        <input
          class="form-control"
          :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand']"
          type="text"
          v-model="details.passNo"
        >
      </div>

      <div
        class="col-12 pb-4"
        v-if="details.countryCd !== 'ZA' && !hasPassportFile"
      >
        <div
          class="col-12 mb-1 mb-md-2 p-0 text-right font5 semibold clickable"
          @click="openPassportInput"
        >
          Upload a copy of passport (optional) <img
            src="../assets/images/arrow_up_beige.png"
            style="height: 23px;"
            class="pl-2"
          >
          <input
            v-show="false"
            type="file"
            name="extra_attachments"
            ref="passportInput"
            @change="fileChanged()"
            multiple
            accept=".doc, .docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*, .pdf, application/pdf"
            maxlength=80
          />
        </div>
        <div
          class="col-12 mb-1 mb-md-2 p-0 font5 semibold"
          v-if="passportFileNames.length > 0"
        >
          <div
            v-for="(f, i) of passportFileNames"
            :key=i
          >{{f}}<span
              @click="removePassportFile(i)"
              class="pl-3 font6 clickable"
            >[ remove ]</span></div>
        </div>
      </div>

      <div
        class="col-lg-6 col-12 pb-4"
        v-if="details.countryCd !== 'ZA'"
      >
        <div class="col-12 mb-1 mb-md-2 p-0">
          Passport Expiry Date
        </div>
        <datepicker
          :input-class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand', 'form-control']"
          :calendar-class="[background !== 'light' ? 'bg_darkest' : 'bg_light']"
          :value="details.passExp"
          @selected="changeDate($event, 'passExp')"
        ></datepicker>
      </div>

      <div
        class="col-lg-6 col-12 pb-4"
        v-if="details.countryCd !== 'ZA'"
      >
        <div class="col-12 mb-1 mb-md-2 p-0">
          Passport Issued
        </div>
        <datepicker
          :input-class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand', 'form-control']"
          :calendar-class="[background !== 'light' ? 'bg_darkest' : 'bg_light']"
          :value="details.passIssued"
          @selected="changeDate($event, 'passIssued')"
        ></datepicker>
      </div>

      <div class="col-lg-6 col-12 pb-4">
        <div class="col-12 mb-1 mb-md-2 p-0">
          Contact Number
        </div>
        <input
          class="form-control"
          :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand']"
          type="text"
          v-model="details.cell"
        >
      </div>

      <div class="col-lg-6 col-12 pb-4">
        <div class="col-12 mb-1 mb-md-2 p-0">
          Contact Number (while travelling)
        </div>
        <input
          class="form-control"
          :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand']"
          type="text"
          v-model="details.cellTravelling"
        >
      </div>

      <div class="col-lg-6 col-12 pb-4">
        <div class="col-12 mb-1 mb-md-2 p-0">
          Emergency Contact - Name
        </div>
        <input
          class="form-control"
          :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand']"
          type="text"
          v-model="details.emergencyContactName"
        >
      </div>

      <div class="col-lg-6 col-12 pb-4">
        <div class="col-12 mb-1 mb-md-2 p-0">
          Emergency Contact - Number
        </div>
        <input
          class="form-control"
          :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand']"
          type="text"
          v-model="details.emergencyContactNumber"
        >
      </div>

      <div class="col-12 pb-4">
        <div class="col-12 mb-1 mb-md-2 p-0">
          Medical Conditions (including allergies)
        </div>
        <input
          class="form-control"
          :class="[background !== 'light' ? 'form-control-transparent' : 'form-control-sand']"
          type="text"
          v-model="details.medicalConditions"
        >
      </div>

      <div
        class="col-md-12 p-0 text-center"
        :class="{'pb-5': !isDesktop}"
      >
        <button
          class="btn button-darkest"
          @click="save"
          :disabled="saving || !(changed || changedPassportFiles)"
        >
          <span v-if="saving">Saving...</span>
          <span v-else-if="saved">Saved</span>
          <span v-else>Save</span>
        </button>
      </div>
    </div>
    <!-- <div class="row m-0">
      <div class="col p-0 montserrat_medium underline">
        Terms & Conditions
      </div>
      <div class="col p-0 text-right" :class="{ 'darkbg': background === 'dark' }">
        <label class="container">Accept
          <input type="checkbox" checked="checked">
          <span class="checkmark"></span>
        </label>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'

const maxTotalPassportFilesSize = 10000000
const maxSinglePassportFileSize = 2000000
export default {
  name: 'Details',
  props: ['background'],
  components: {
    Datepicker,
    vSelect
  },
  data: () => ({
    changedPassportFiles: false,
    details: {
      cell: null,
      cellTravelling: null,
      countryCd: null,
      dob: null,
      emergencyContactName: null,
      emergencyContactNumber: null,
      idNo: null,
      medicalConditions: null,
      name: null,
      namePreferred: null,
      passExp: null,
      passIssued: null,
      passNo: null,
      surname: null,
      title: null
    },
    loading: true,
    passportFiles: [],
    passportFileNames: [],
    saved: false,
    saving: false,
    titles: [
      { name: 'Mr.', value: 'mr' },
      { name: 'Mrs.', value: 'mrs' },
      { name: 'Ms.', value: 'ms' },
      { name: 'Miss', value: 'miss' },
      { name: 'Dr.', value: 'dr' }
    ]
  }),
  computed: {
    ...mapGetters(['countries', 'isMobile', 'isTablet', 'profile']),
    changed () {
      if (this.details) {
        for (const [k, v] of Object.entries(this.details)) {
          if (this.profile[k] !== v) {
            return true
          }
        }
      }
      return false
    },
    hasPassportFile () {
      if (this.profile && this.profile.documents) {
        const pass = this.profile.documents.filter(
          doc => doc.type === 'passport'
        )
        return pass && pass.length > 0
      }
      return false
    },
    isDesktop () {
      return !this.isMobile && !this.isTablet
    },
    totalSizePassportFiles () {
      let totalSize = 0
      for (const f of Object.values(this.passportFiles)) {
        totalSize += f.size
      }
      return totalSize
    }
  },
  created () {
    if (this.profile) {
      this.clear()
    } else {
      console.log('c')

      this.getProfile().then(() => {
        this.clear()
      })
    }
    this.getCountries().then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions([
      'getCountries',
      'getProfile',
      'editProfile',
      'uploadDocument'
    ]),
    changeDate (date, field) {
      this.details[field] = moment(date).format('YYYY-MM-DD')
    },
    fileChanged (event) {
      const fileList = this.$refs.passportInput.files
      if (fileList) {
        for (const f of Object.values(fileList)) {
          if (
            this.totalSizePassportFiles + f.size >
            maxTotalPassportFilesSize
          ) {
            alert(
              'Max total size for attachments is ' +
                maxTotalPassportFilesSize / 1000000 +
                'Mb'
            )
            break
          } else if (f.size > maxSinglePassportFileSize) {
            alert(
              'File "' +
                f.name +
                '" is too big. Max size ' +
                maxSinglePassportFileSize / 1000000 +
                'Mb'
            )
          } else {
            this.changedPassportFiles = true
            this.passportFiles.push(f)
            this.passportFileNames.push(f.name)
          }
        }
      }
    },
    openPassportInput () {
      this.$refs.passportInput.click()
    },
    clear () {
      for (const k of Object.keys(this.details)) {
        this.details[k] = this.profile[k]
      }
    },
    removePassportFile (i) {
      this.changedPassportFiles = true
      this.passportFiles.splice(i, 1)
      this.passportFileNames.splice(i, 1)
    },
    save () {
      this.saving = true
      let promise = Promise.resolve(true)
      if (this.changed) {
        const myDetails = {}
        for (const [k, f] of Object.entries(this.details)) {
          if (f !== null && typeof f !== 'undefined') {
            myDetails[k] = f
          }
        }
        promise = this.editProfile(myDetails)
      }
      promise
        .then(() => {
          promise = Promise.resolve(true)
          if (this.passportFiles) {
            const formData = new FormData()
            formData.append('type', 'passport')
            for (const [k, f] of Object.entries(this.passportFiles)) {
              formData.append('files[' + k + ']', f)
            }
            promise = this.uploadDocument(formData)
          }
          return promise
        })
        .then(() => {
          this.changedPassportFiles = false
          this.passportFiles = []
          this.passportFileNames = []
          this.saving = false
          this.saved = true
          setInterval(() => {
            this.saved = false
          }, 6000)
          return this.getProfile()
        })
        .then(
          () => {
            this.clear()
            if (this.$route.name === 'Profile') {
              this.$router.push('/')
            }
          },
          err => {
            console.error(err)
            this.saving = false
            this.saved = false
          }
        )
    }
  }
}
</script>
<style scoped>
</style>
